export class AddressValidation {
    address: string;
    city: string;
    state?:number;    
    zipcode: string
}

export class ImportedAddressList {
    id?: any;
    address: string;
    city: string;
    state?:number;    
    zipcode: string
    createdBy?: any;
    createdDate?: any;
    totalRecordCount?: any;
}