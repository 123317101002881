import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/Services/users.service';
import { ToastrService } from 'ngx-toastr';
import { Globle } from 'src/app/Shared/global';
import { Router, ActivatedRoute } from '@angular/router';
import { getIntParam, copyToClipboard } from 'src/app/Shared/commonMethods';
import { EmailHistoryModel } from 'src/Models/EmailHistoryModel';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-email-history',
  templateUrl: './email-history.component.html',
  styleUrls: ['./email-history.component.css']
})
export class EmailHistoryComponent implements OnInit {
  listofEmail: EmailHistoryModel[];
  isloading = false;
  applicationUserId: number = 0;
  emailContent: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UsersService, private globle: Globle, private toastr: ToastrService, private titleService: Title) {
    this.applicationUserId = getIntParam(this.activatedRoute.params, 'id');
    this.titleService.setTitle('Email History');
  }

  ngOnInit() {
    this.GetEmailHistory();
  }

  GetEmailHistory() {
    this.isloading = true;
    this.listofEmail = [];
    this.userService.GetEmailtHistory(this.applicationUserId).subscribe(
      data => {
        this.isloading = false;
        this.listofEmail = data;
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  ViewEmailTemplate(content) {
    this.emailContent = "";
    this.emailContent = content;
  }

  returnToList() {
    this.router.navigate(['/admin/applicants']);
  }
}