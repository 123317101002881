import { Injectable, Inject } from '@angular/core';
import { catchError, map, debounce } from 'rxjs/operators';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { DataService } from './data.service';
import { environment } from 'src/environments/environment';
import { Http, RequestMethod, Response, ResponseContentType, Headers } from '@angular/http';
import { PaginationModel } from 'src/Models/paginationModel';
@Injectable({
    providedIn: 'root'
})
export class importlocationService {
    constructor(private _data: DataService, private _http: Http) { }
   
    UploadExcelFile(userId: any = 0, formData: FormData): Observable<any> {
        return this._data.postFromData(`importaddress/uploadexcel/` + userId, formData)
            .pipe(map(response => {
                return response;
            }));
    }
    DownloadDocument(TokenKey, path: any): Observable<any> {
        let data = 'importaddress/downloaddocument';
        return this._http.post(`${environment.apiURL}api/` + data, path, {
            method: RequestMethod.Post, responseType: ResponseContentType.Blob,
            headers: new Headers({ 'Token': TokenKey })
        }).pipe(
            map(
                (res) => {
                    return new Blob([res.blob()], { type: 'application/octet-stream' });
                }));
    }


    GetAddressByPaged(pagination: PaginationModel): Observable<any> {
        return this._data.post(`importaddress/GetAddressByPaged`, pagination)
            .pipe(map(response => {
                return response;
            }));
    }

    DeleteAddressFindById(id: any): Observable<any> {
        return this._data.post(`importaddress/DeleteAddressFindById/` + id, id)
            .pipe(map(response => {
                return response;
            }));
    }

    DeleteAllAddress(): Observable<any> {
        return this._data.post(`importaddress/DeleteAllAddress`)
            .pipe(map(response => {
                return response;
            }));
    }
}