import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  constructor(private _data: DataService) {
  }

  GetStateList(): Observable<any> {
    return this._data.get(`state/GetStateList`)
      .pipe(map(response => {
        return response;
      }));
  }

  GetProviderStateList(Id: number): Observable<any> {
    return this._data.get(`state/GetProviderStateList/` + Id)
      .pipe(map(response => {
        return response;
      }));
  }

  GetStateListEBB(): Observable<any> {
    return this._data.get(`state/GetStateListEBB`)
      .pipe(map(response => {
        return response;
      }));
  }

  ValidateAddress(addressinfo): Observable<any> {
    return this._data.post(`state/validateaddress`, addressinfo)
      .pipe(map(response => {
        return response;
      }));
  }

}
