import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { importlocationService } from 'src/Services/importlocation.service';
import { Globle } from 'src/app/Shared/global';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-importlocation',
  templateUrl: './importlocation.component.html',
  styleUrls: ['./importlocation.component.css']
})
export class ImportlocationComponent implements OnInit {
  file: File;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;

  constructor(private globle: Globle, private importlocationService: importlocationService, private toastr: ToastrService,
    private titleService: Title, private router: Router) {
    this.titleService.setTitle("Address Import");
  }

  ngOnInit() {
  }

  onFileSelect(file) {
    this.file = file.target.files[0];
  }

  onFileUpload() {
    this.blockDocument();
    let formData = new FormData();
    if (this.file)
      formData.append("file", this.file);
    this.importlocationService.UploadExcelFile(this.globle.cUser.id, formData).subscribe(res => {
      this.file = undefined;
      if (res) {
        if (res.status == "Success") {
          document.getElementById("uploadFile").textContent = "";
          if (res.responseNewFilename) {
            this.importlocationService.DownloadDocument(this.globle.cUser.authToken, { path: res.responseNewFilename }).subscribe(
              fileData => {
                this.unblockDocument();
                if (fileData != null) {
                  FileSaver.saveAs(fileData, res.responseNewFilename);
                  this.toastr.success(res.message, res.status);
                  //this.router.navigate(['admin/online/register/user']);
                }
              }, error => {
                this.unblockDocument();
              });
          }
          else {
            this.unblockDocument();
            this.toastr.success(res.message, res.status);
            //this.router.navigate(['admin/online/register/user']);
          }

        }
        else {
          this.toastr.error(res.message, res.status);
          this.unblockDocument();
        }
      }
      else
        this.unblockDocument();
    }, error => {
      this.unblockDocument();
    })
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  redirectToAddresslist() {
    this.router.navigate(['admin/address/list']);
  }

}
