export class BillingFilterModel {
    providerId: number;
    effStartDate: any;
    effEndDate: any;
    pageNumber: any;
    pageSize: any;
    whereCondition: any;
    orderBy: any;
    providerIds: any;
    billingStatus: any;
    isActive: any;
    isSuccessEnrolled: any;
    isCancelled: any;
    isApplication: any;
    customerType: any;
}