import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { movetotop } from 'src/app/Shared/commonMethods';
import { PaginationModel } from 'src/Models/paginationModel';
import { Globle } from 'src/app/Shared/global';
import { Subject } from 'rxjs';
import { Title } from "@angular/platform-browser";
import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { filterParameter } from 'src/Models/filterParameter';
import { importlocationService } from 'src/Services/importlocation.service';
import { ImportedAddressList } from 'src/Models/AddressValidation';

@Component({
  selector: 'app-locationlist',
  templateUrl: './locationlist.component.html',
  styleUrls: ['./locationlist.component.css']
})
export class LocationlistComponent implements OnInit {
  listOfData: ImportedAddressList[];
  pagination: PaginationModel = new PaginationModel();
  isloading: boolean = false;
  Id: number = 0;
  isAscending: boolean;
  searchTextChanged = new Subject<string>();

  pageSize: number = 0;
  pageIndex: number = 0;
  totalRecordsCount: number = -1;

  upDowAddressCSS: string = '';
  upDowCityCSS: string = '';
  upDowStateCSS: string = '';
  upDowZipCodeCSS: string = '';
  upDowCreatedbyCSS: string = '';
  upDowCreatedDateCSS: string = '';

  deletedId: number = 0;
  @ViewChild('closeDeleteConfirmation') closeDeleteConfirmation: ElementRef;
  @ViewChild('openProceedModal') openProceedModal: ElementRef;

  paginationExport: PaginationModel = new PaginationModel();

  filterParameter = new filterParameter();

  constructor
    (
      private router: Router,
      private importlocationService: importlocationService,
      private globle: Globle,
      private titleService: Title,
      private toastr: ToastrService,
  ) {
    this.pagination.orderBy = 'CreatedDate Desc';
    this.pagination.pageNumber = 1;
    this.pagination.pageSize = 10;
    this.pagination.userId = this.globle.cUser.id.toString();
    this.titleService.setTitle("Address Import");
  }

  ngOnInit() {
    movetotop();
    this.GetAddressByPaged();
  }

  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.pagination.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';
    switch (sortBy) {
      case 'Address': this.upDowAddressCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'City': this.upDowCityCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'State': this.upDowStateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'ZipCode': this.upDowZipCodeCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'Createdby': this.upDowCreatedbyCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'CreatedDate': this.upDowCreatedDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.GetAddressByPaged();
  }

  cleanCssClass() {
    this.upDowAddressCSS = '';
    this.upDowCityCSS = '';
    this.upDowStateCSS = '';
    this.upDowZipCodeCSS = '';
    this.upDowCreatedbyCSS = '';
    this.upDowCreatedDateCSS = '';
  }

  keywordFilters($event) {
    this.isloading = true;
    this.pagination.pageNumber = 1;
    this.pagination.whereCondition = $event.target.value;
    this.GetAddressByPaged();
  }

  pageChanged($event: any) {
    this.pagination.pageNumber = 1;
    this.pagination.pageNumber = parseInt($event.page);
    this.GetAddressByPaged();
  }

  GetAddressByPaged() {
    this.isloading = true;
    this.importlocationService.GetAddressByPaged(this.pagination).subscribe(
      data => {
        this.isloading = false;
        this.listOfData = data;
        if (this.listOfData != null) {
          if (this.listOfData.length > 0) {
            this.totalRecordsCount = this.listOfData[0].totalRecordCount;
          }
          else
            this.totalRecordsCount = 0;
        }
        else
          this.totalRecordsCount = 0;
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  redirectToAddAddress() {
    this.router.navigate(['admin/address/import']);
  }

  setDeleteAddress(id) {
    this.deletedId = id;
  }

  deleteAddress(id) {
    this.importlocationService.DeleteAddressFindById(this.deletedId).subscribe(
      data => {
        this.deletedId = 0;
        if (data > 0) {
          this.toastr.success('Record deleted successfully', 'Delete');
          this.closeDeleteConfirmation.nativeElement.click();
          this.GetAddressByPaged();
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  deleteAllAddress() {
    this.importlocationService.DeleteAllAddress().subscribe(
      data => {
        if (data > 0) {
          this.toastr.success('All Record deleted successfully', 'Delete');
          this.GetAddressByPaged();
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  deleteConfirmation() {
    this.openProceedModal.nativeElement.click();
  }
}
